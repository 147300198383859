// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.Z6uVNisThbv4Cl4iKcjA{border-radius:4px !important;width:140px !important;grid-template-rows:0px 28px 130px !important}.eF7fgTEq0Bs7PbgUBht5{display:none}.xb3QwUzkY_5N0tN6qH4W{transform:translateY(-2px)}.kU6pun9_xku4mHm7d5sn{grid-row:3;overflow:hidden;width:100%;height:100%;user-select:none;pointer-events:none}`, "",{"version":3,"sources":["webpack://./../../../tools/nodeEditor/dist/graphSystem/display/debugDisplayManager.module.scss"],"names":[],"mappings":"AAAA,sBACI,4BAAA,CACA,sBAAA,CACA,4CAAA,CAGJ,sBACI,YAAA,CAGJ,sBACI,0BAAA,CAGJ,sBACI,UAAA,CACA,eAAA,CACA,UAAA,CACA,WAAA,CACA,gBAAA,CACA,mBAAA","sourcesContent":[".debugBlock {\r\n    border-radius: 4px !important;\r\n    width: 140px !important;\r\n    grid-template-rows: 0px 28px 130px !important;\r\n}\r\n\r\n.hidden {\r\n    display: none;\r\n}\r\n\r\n.translatedConnections {\r\n    transform: translateY(-2px);\r\n}\r\n\r\n.texture-area {\r\n    grid-row: 3;\r\n    overflow: hidden;\r\n    width: 100%;\r\n    height: 100%;\r\n    user-select: none;\r\n    pointer-events: none;\r\n}\r\n"],"sourceRoot":""}]);
// Exports
export var debugBlock = `Z6uVNisThbv4Cl4iKcjA`;
export var hidden = `eF7fgTEq0Bs7PbgUBht5`;
export var translatedConnections = `xb3QwUzkY_5N0tN6qH4W`;
var _1 = `kU6pun9_xku4mHm7d5sn`;
export { _1 as "texture-area" };
export default ___CSS_LOADER_EXPORT___;
