// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.hol3vmg6S3RmMX2BDnJQ{margin-top:80px}.jI6eo5eT93r0D5eOTjnu{margin-top:30px}.pG2BSy_fF3kPC8Kb7oq9{grid-row:3 !important}.JSNytgcYueYEIEHDn_eh{margin-top:155px}.YtzUAOeZNDFTFHo93qGQ{margin-top:80px}`, "",{"version":3,"sources":["webpack://./../../../tools/nodeEditor/dist/graphSystem/display/textureDisplayManager.module.scss"],"names":[],"mappings":"AAAA,sBACI,eAAA,CAGJ,sBACI,eAAA,CAGJ,sBACI,qBAAA,CAGJ,sBACI,gBAAA,CAGJ,sBACI,eAAA","sourcesContent":[".regular-texture-block {\r\n    margin-top: 80px;\r\n}\r\n\r\n.reduced-texture-block {\r\n    margin-top: 30px;\r\n}\r\n\r\n.reflection-block {\r\n    grid-row: 3 !important;\r\n}\r\n\r\n.triplanar-texture-block {\r\n    margin-top: 155px;\r\n}\r\n\r\n.refraction-texture-block {\r\n    margin-top: 80px;\r\n}\r\n"],"sourceRoot":""}]);
// Exports
var _1 = `hol3vmg6S3RmMX2BDnJQ`;
export { _1 as "regular-texture-block" };
var _2 = `jI6eo5eT93r0D5eOTjnu`;
export { _2 as "reduced-texture-block" };
var _3 = `pG2BSy_fF3kPC8Kb7oq9`;
export { _3 as "reflection-block" };
var _4 = `JSNytgcYueYEIEHDn_eh`;
export { _4 as "triplanar-texture-block" };
var _5 = `YtzUAOeZNDFTFHo93qGQ`;
export { _5 as "refraction-texture-block" };
export default ___CSS_LOADER_EXPORT___;
