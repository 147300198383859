// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.xp0svdQQ7lHcLyymo9_p{filter:brightness(2)}.mzsVp6_QOFaQygXylaeh{background:green;width:6px;height:6px;grid-row:1;grid-column:1;align-self:center;justify-self:center;border-radius:7px;border:2px solid #000}.mzsVp6_QOFaQygXylaeh.AGhAEUJ1eJ3ZffrDebig{display:none}.IT0tpBwBXY8zEbeGQPPk{grid-row:1;grid-column:1;background:rgba(0,0,0,0)}.IT0tpBwBXY8zEbeGQPPk img{pointer-events:none}.rKcZEGmYSLWPT9jzSKaT{box-shadow:0 0 0 2px;border-radius:50%}.H7CK8OcG_4iJRPTQkrhq{grid-column:2;grid-row:1;margin:0px !important;padding-top:0px !important}.H7CK8OcG_4iJRPTQkrhq div{margin-top:2px}`, "",{"version":3,"sources":["webpack://./../../../dev/sharedUiComponents/dist/nodeGraphSystem/nodePort.module.scss"],"names":[],"mappings":"AAAA,sBACI,oBAAA,CAGJ,sBACI,gBAAA,CACA,SAAA,CACA,UAAA,CACA,UAAA,CACA,aAAA,CACA,iBAAA,CACA,mBAAA,CACA,iBAAA,CACA,qBAAA,CAEA,2CACI,YAAA,CAIR,sBACI,UAAA,CACA,aAAA,CACA,wBAAA,CAEA,0BACI,mBAAA,CAIR,sBACI,oBAAA,CACA,iBAAA,CAGJ,sBACI,aAAA,CACA,UAAA,CACA,qBAAA,CACA,0BAAA,CAEA,0BACI,cAAA","sourcesContent":[".selected {\r\n    filter: brightness(2);\r\n}\r\n\r\n.pip {\r\n    background: green;\r\n    width: 6px;\r\n    height: 6px;\r\n    grid-row: 1;\r\n    grid-column: 1;\r\n    align-self: center;\r\n    justify-self: center;\r\n    border-radius: 7px;\r\n    border: 2px solid black;\r\n\r\n    &.hidden {\r\n        display: none;\r\n    }\r\n}\r\n\r\n.port-icon {\r\n    grid-row: 1;\r\n    grid-column: 1;\r\n    background: transparent;\r\n\r\n    img {\r\n        pointer-events: none;\r\n    }\r\n}\r\n\r\n.icon-selected {\r\n    box-shadow: 0 0 0 2px;\r\n    border-radius: 50%;\r\n}\r\n\r\n.numberContainer {\r\n    grid-column: 2;\r\n    grid-row: 1;\r\n    margin: 0px !important;\r\n    padding-top: 0px !important;\r\n\r\n    div {\r\n        margin-top: 2px;\r\n    }\r\n}\r\n"],"sourceRoot":""}]);
// Exports
export var selected = `xp0svdQQ7lHcLyymo9_p`;
export var pip = `mzsVp6_QOFaQygXylaeh`;
export var hidden = `AGhAEUJ1eJ3ZffrDebig`;
var _1 = `IT0tpBwBXY8zEbeGQPPk`;
export { _1 as "port-icon" };
var _2 = `rKcZEGmYSLWPT9jzSKaT`;
export { _2 as "icon-selected" };
export var numberContainer = `H7CK8OcG_4iJRPTQkrhq`;
export default ___CSS_LOADER_EXPORT___;
