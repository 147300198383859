// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.y3r0uoRbB7Q9cxBRLbat{margin-top:5px}`, "",{"version":3,"sources":["webpack://./../../../tools/nodeEditor/dist/graphSystem/display/imageSourceDisplayManager.module.scss"],"names":[],"mappings":"AAAA,sBACI,cAAA","sourcesContent":[".image-source-block {\r\n    margin-top: 5px;\r\n}\r\n"],"sourceRoot":""}]);
// Exports
var _1 = `y3r0uoRbB7Q9cxBRLbat`;
export { _1 as "image-source-block" };
export default ___CSS_LOADER_EXPORT___;
